<template>
  <Title pageTitle="CONTACT" pageDiscrption="お問い合わせ・リーフレット請求" />
  <div class="container">
    <div class="container-narrow">
      <form method="post" action="./php/contact.php" class="form" @submit="goThanks()">
        <div class="contact-btn">
          <input @change="query" type="radio" name="種別" value="お問い合わせ" id="query" checked="" />
          <label class="contact-btn__item" for="query">お問い合わせ</label>
          <input @change="leaflet" type="radio" name="種別" value="生地見本付きリーフレット請求" id="leaflet" />
          <label class="contact-btn__item" for="leaflet">生地見本付きリーフレット請求</label>
        </div>

        <div class="form__item">
          <label for="name">お名前</label>
          <input type="text" id="name" name="お名前" placeholder="お名前をご記入ください" required />
        </div>
        <div class="form__item">
          <label for="email">メールアドレス</label>
          <input
            type="email"
            id="email"
            name="メールアドレス"
            placeholder="お間違いのないよう正確にご記入ください"
            oncopy="return false"
            onpaste="return false"
            oncontextmenu="return false"
            required
          />
        </div>

        <div v-if="isActive" class="leaf">
          <div class="form__item">
            <label for="code">郵便番号</label>
            <input type="number" id="code" name="郵便番号" placeholder="ハイフンなしでご記入ください" required />
          </div>
          <div class="form__item">
            <label for="address">住所</label>
            <input type="text" id="address" name="住所" placeholder="都道府県からご記入ください" required />
          </div>
          <div class="form__item">
            <label for="tel">電話番号</label>
            <input type="tel" id="tel" name="電話番号" placeholder="ハイフンなしでご記入ください" required />
          </div>
        </div>

        <div class="form__item textarea">
          <label for="content">お問い合わせ内容</label>
          <textarea id="content" name="お問い合わせ内容" required></textarea>
        </div>
        <div class="form__button">
          <button type="submit">送信する</button>
          <button type="reset">リセット</button>
        </div>
      </form>
      <div class="note">48時間以内に返信がない場合は、お手数ですがメールにてご連絡ください。</div>
      <div class="note">自動返信メールが届かない場合はメールアドレスが間違っている可能性があります。</div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Title from '../components/Title.vue'
import { useRouter } from 'vue-router'

export default {
  components: { Title },
  setup() {
    const isActive = ref(false)
    const query = () => {
      isActive.value = false
    }
    const leaflet = () => {
      isActive.value = true
    }

    const router = useRouter()
    const goThanks = () => {
      router.push({ name: 'Thanks' })
    }

    return { isActive, query, leaflet, goThanks }
  },
}
</script>

<style lang="scss" scoped>
.contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }

  & input {
    display: none;
  }

  & input:checked + label {
    border: 1px solid var(--color-light);
    background-color: #1a1a1a;
  }

  &__item {
    width: 300px;
    margin: 0 10px;
    color: var(--color-light);
    background-color: transparent;
    padding: 15px 20px 17px;
    border: 1px solid #555;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease-out;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    &:hover {
      border: 1px solid #aaa;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    border-bottom: 1px solid var(--color-dark);
    padding: 1rem 0;
    position: relative;

    @media screen and (max-width: 599px) {
      grid-template-columns: 1fr;
    }

    & label {
      display: inline-block;
      text-align: center;

      @media screen and (max-width: 599px) {
        text-align: left;
        margin: 10px 0 10px 20px;
      }
    }

    & input,
    & textarea {
      font-family: inherit;
      display: inline-block;
      padding: 20px;
      background-color: transparent;
      border: none;
      outline: none;
      color: var(--color-light);
      font-size: 15px;
    }

    & textarea {
      height: 150px;
      line-height: 1.6;
    }

    & div:last-child {
      border-bottom: none;
    }

    & small {
      position: absolute;
      top: 40%;
      right: 2rem;
      color: red;

      @media screen and (max-width: 599px) {
        top: 60%;
      }
    }
  }

  &__button {
    display: flex;
    width: 100%;
    justify-content: center;

    & button {
      width: 30%;
      padding: 1.5rem 2rem;
      margin: 5rem 1rem 3rem;
      outline: none;
      background-color: #555;
      border: none;
      border-radius: 100px;
      cursor: pointer;

      @media screen and (max-width: 599px) {
        width: 50%;
      }

      &:first-child {
        background-color: var(--color-tailor-dark);

        &:first-child:hover {
          background-color: var(--color-tailor-light);
        }
      }
    }
  }
}
.leaf {
  width: 100%;
}
.note {
  margin: 0 auto 5px;
  text-align: center;
  font-size: 12px;

  @media screen and (max-width: 425px) {
    text-align: left;
  }
}
</style>
